.wrapper .bo-menu {
	background: orange;
	height: 56px;

	ul {
		padding: 9px 24px;
		margin: 0;
	}

	li {
		display: inline-block;
		margin-right: 10px;

		a {
			cursor: pointer;
		}
	}
}
