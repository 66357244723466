@import "../../../helpers/variables";

.menu {
	background: url("/white-waves.webp");
	border-bottom: 1px solid $menu-border-color;
	z-index: 1000;

	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;

		.brand-image {
			height: 50px;
			display: inline-block;
			vertical-align: bottom;
			padding-top: 5px;
			margin-right: 15px;

			img {
				width: 40px;
				height: 40px;
				margin-right: 6px;
			}
		}

		.brand-name {
			display: inline-block;

			div:last-child {
				margin-top: -10px;
			}
		}
	}
}
