.user {
	.card-header {
		img.card-avatar {
			border-radius: 50%;
		}

		.user-name {
			display: inline-block;
			float: right;
			line-height: 80px;
			margin: 0;
			font-size: 20px;
			font-weight: bold;
		}
	}
}
