@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.page.home {
	img {
		max-width: 150px;
	}

	.introduction {
		margin: 0 auto 80px auto;
		text-align: center;

		img {
			margin-bottom: 20px;
			max-width: 300px;
			border-radius: 50%;
		}
	}

	.image-holder {
		position: relative;

		.section-image {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
		}
	}

	.section {
		margin-bottom: 40px;

		&:nth-child(odd) {
			flex-direction: row-reverse;
		}

		.text-holder {
			position: relative;
			min-height: 150px;

			p {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.image-holder {
			text-align: center;

			.section-image {
				position: relative;
				top: 0;
				left:0;
				transform: none;

				margin-bottom: 20px;
			}
		}

		.section .text-holder p {
			position: relative;
			top: 0;
			transform: none;
		}

		.introduction img {
			width: 100%;
		}
	}
}
