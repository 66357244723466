.page.book {
	&.offline {
		margin: auto;
		padding-top: 40px;
		text-align: center;

		.contacts {
			margin-top: 16px;

			a {
				font-size: 36px;
			}
		}

		.or {
			font-size: 30px;
		}
	}
}
