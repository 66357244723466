@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../helpers/variables";

.footer {
	font-size: 12px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: $footer-height;
	border-top: 1px solid $menu-border-color;

	background: url("/white-waves.webp");
	overflow: auto;

	.container {
		margin-top: 6px;
	}

	@media (max-width: 575px) {
		.no-mobile {
			visibility: hidden !important;
			display: none !important;
		}

		text-align: center;

		.row {
			& > div {
				margin-top: 6px;
			}
		}
	}
}
