.page.info {
	#map-container {
		width: 100%;
		height: 400px;

		margin-bottom: 20px;
		border: 1px solid;

		&.hidden {
			display: none;
			visibility: hidden;
		}
	}
}
