.swiper-container {
	width: 100%;
	margin-bottom: 20px;

	.swiper-slide {

		.swiper-image {
			width: 100%;
		}
	}
}
