body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
	.universal-load {
		display: none;
		visibility: hidden;
	}

	&.pace-running {
		.universal-load {
			display: block;
			visibility: visible;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: white;
			z-index: 1;
		}
	}
}
