@import "./helpers/variables";
@import "./bo-menu";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
	background: url("/symphony.webp");
}

html, body, #root {
	height: 100%;
	width: 100%;
}

.loading-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
}


.loading {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.alert-area {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 20px;
	right: 20px;
}

.wrapper {
	&.logged {
		.app-content {
			top: 2 * $menu-height;
		}
	}

	.bo-menu {
		background: orange;
		height: $menu-height;

		ul {
			padding: 9px 24px;
			margin: 0;
		}

		li {
			display: inline-block;
			margin-right: 10px;

			a {
				cursor: pointer;
			}
		}
	}

	.app-content {
		position: absolute;
		top: $menu-height;
		bottom: $footer-height;
		left: 0;
		right: 0;

		padding: 40px;
		overflow: auto;

		& > *:last-child {
			margin-bottom: 20px !important;
		}
	}

	.nav-link.book-btn {
		&:focus {
			box-shadow: none;
		}

		&:hover {
			color: white;
		}
	}

	.react-calendar__month-view__weekdays {
		color: black;
	}
}

.navbar-collapse {
	padding: 1em;
}

.navbar-toggler {
	margin-right: .5em;
}
