.loading-wrapper {
	&.full {
		height: 100%;
		width: 100%;
		position: relative;

		.loading {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&.dark {
		.loading div {
			fill: #333 !important;
		}
	}
}
