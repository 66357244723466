@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../helpers/font-awesome";

.section-page {
	position: relative;
	width: 100%;
	height: 100%;

	.section-main-title {
		text-align: center;
		padding-top: 40px;
		font-size: 50px;
	}

	.section-menu {
		position: absolute;
		left: 20px;
		width: 220px;
		top: 50%;
		transform: translateY(-50%);
		border-right: 1px solid #3486cb;
		padding: 20px 0;

		.nav {
			display: block;

			.nav-link.active {
				position: relative;

				&:before {
					position: absolute;
					left: -1ex;
					content: "\f138";
					@include font-awesome;
				}
			}
		}
	}

	.section-wrapper {
		position: absolute;
		left: 250px;
		top: 0;
		right: 0;
		bottom: 0;
		padding: 200px;
	}

	.section-separator {
		text-align: center;
		margin-top: 16px;
		margin-bottom: 16px;

		img {
			height: 10px;
		}
	}

	@include media-breakpoint-down(sm) {
		.section-wrapper {
			position: relative;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			padding: 0;
			margin-top: 60px;

			.section {
				margin-bottom: 40px;
			}
		}
	}
}
